<template>
  <v-list v-if="vectorFeature.length">
    <v-list-item>
      <v-list-item-avatar>
        <v-icon>
          mdi-scatter-plot
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ activeVectorLayer.layerName }}</v-list-item-title
        >
        <v-list-item-title>
          {{ activeVectorLayer.parameterName }}</v-list-item-title
        >
        <v-list-item-subtitle>
          {{ activeVectorLayer.startDate }} to
          {{ activeVectorLayer.endDate }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item-group class="scrollable-list custom-scrollbar">
      <v-list-item
        class="ml-5"
        v-for="(item, index) in this.vectorFeature"
        :key="index"
      >
        <v-list-item-icon class="pl-3 pt-2">
          <v-icon x-small>
            mdi-checkbox-blank-circle
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle
            class="primary--text text--darken-2
         "
          >
            {{ displayStationName[index] }}
          </v-list-item-subtitle>
          <template v-if="shpType === 'timeseries'">
            <v-list-item-subtitle class="text--primary" v-if="dataAvailable">
              <b v-if="displayEntriesValue[index] > 1">
                Ø: {{ displayAvgValue[index] }} {{ unit }}, {{ $t("entries") }}:
                {{ displayEntriesValue[index] }}
              </b>
              <b v-else> {{ displayAvgValue[index] }} {{ unit }} </b>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary" v-else>
              ...
            </v-list-item-subtitle>
          </template>
          <template v-else-if="shpType === 'timesteps'">
            <v-list-item-subtitle>
              {{ timestep }}
            </v-list-item-subtitle>
          </template>
        </v-list-item-content>
        <v-list-item-action v-if="shpType === 'timeseries'">
          <template v-if="dataLoading[index]">
            <v-progress-circular
              indeterminate
              color="accent"
            ></v-progress-circular>
          </template>

          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                x-small
                v-bind="attrs"
                v-on="on"
                color="accent"
                @click="addTimeseriesToPlot(index)"
                :disabled="addButtonDisabled(index)"
              >
                <v-icon v-if="addButtonDisabled(index)">mdi-check-bold</v-icon>
                <v-icon v-else>mdi-chart-box-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("addToPlot") }}</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Url from "@/core/services/url.services";
import axios from "axios";
import PlotUtils from "@/core/utils/plot.utils";

export default {
  name: "VectorItem",
  props: ["coordinates"],
  data: () => ({
    vectorFeature: null,
    dataAvailable: false,
    addButtonClicked: [],
    dataLoading: []
  }),
  computed: {
    ...mapState("vector", ["activeVectorLayer"]),
    ...mapGetters("plot", ["disabledPlotButton"]),
    ...mapState("inSitu", ["selectedInSituStation"]),
    displayAvgValue() {
      const parameter = this.activeVectorLayer.parameterId.toLowerCase();
      return this.vectorFeature.map(feature => {
        return feature.properties[`${parameter}_avg`].toFixed(2);
      });
    },
    displayParameter() {
      const parameter = this.activeVectorLayer.parameterId.toLowerCase();
      return this.vectorFeature.map(feature => {
        return feature.properties[parameter];
      });
    },
    displayEntriesValue() {
      const parameter = this.activeVectorLayer.parameterId;
      return this.vectorFeature.map(feature => {
        return feature.properties[`${parameter}_entries`];
      });
    },
    displayStationName() {
      return this.vectorFeature.map(feature => {
        return feature.properties["name"];
      });
    },
    addButtonDisabled() {
      return index => {
        return this.addButtonClicked[index] && !this.disabledPlotButton;
      };
    },
    shpType() {
      return this.activeVectorLayer.shpType;
    },
    unit() {
      return this.activeVectorLayer.unit;
    },
    timestep() {
      return this.activeVectorLayer.timestep;
    }
  },
  methods: {
    ...mapActions("plot", [
      "addDataEntry",
      "setShowPlotFeature",
      "setShowPlot"
    ]),
    queryVectorLayer() {
      const map = this.$store.visualizeMap;
      const pixelCoordinates = map.project(this.coordinates);
      const vectorLayerName = "vectorLayer";
      const vectorLayer = map.getLayer(vectorLayerName);
      let features;
      if (vectorLayer) {
        features = map.queryRenderedFeatures(pixelCoordinates, {
          layers: [vectorLayerName]
        });
      } else {
        return;
      }

      this.vectorFeature = features.filter(
        entry => entry.source === "vectorSource"
      );
      this.dataAvailable = true;
    },
    async fetchTimeseries(index) {
      this.$set(this.dataLoading, index, true);
      const property = this.activeVectorLayer.parameterId;
      const url = Url.vectorTimeseries({
        geometryId: this.vectorFeature[index].properties.layer_geometry_id,
        property: property,
        startDate: this.activeVectorLayer.startDate,
        endDate: this.activeVectorLayer.endDate
      });
      const response = await axios.get(url);
      const date_times = response.data.map(entry => entry.datetime);
      const values = response.data.map(entry => entry.properties[property]);
      this.$set(this.dataLoading, index, false);
      this.$set(this.addButtonClicked, index, true);
      this.addButtonDisabled(index);
      return { date_times: date_times, values: values };
    },
    async addTimeseriesToPlot(index) {
      this.setShowPlot(true);
      this.setShowPlotFeature(true);
      const data = await this.fetchTimeseries(index);
      const legendName = PlotUtils.createVectorLegendName({
        layerName: this.activeVectorLayer.layerName,
        parameterName: this.activeVectorLayer.parameterName,
        stationNameLabel: this.$t("stationNameLabel"),
        stationName: this.vectorFeature[index].properties["name"],
        unit: this.activeVectorLayer.unit,
        tempResNameLabel: this.$t("resolution")
      });
      let ident = `${this.activeVectorLayer.layerId}_${this.coordinates.lat}_${this.coordinates.lng}`;
      const plotData = PlotUtils.createPlotEntry({
        layerId: this.activeVectorLayer.layerId,
        layerType: "vector",
        x: data.date_times,
        y: data.values,
        legendName: legendName,
        unit: this.activeVectorLayer.unit,
        ident: ident
      });
      this.addDataEntry(plotData);
    }
  },
  watch: {
    activeVectorLayer() {
      this.addButtonClicked = [];
      this.dataAvailable = false;
      setTimeout(this.queryVectorLayer, 1000);
    },
    disabledPlotButton(val) {
      if (val) {
        this.addButtonClicked = [];
      }
    }
  },
  created() {
    this.queryVectorLayer();
  }
};
</script>

<style scoped>
.scrollable-list {
  max-height: 300px;
  overflow-y: auto;
  margin-right: 2px;
}
.scrollable-list::-webkit-scrollbar {
  width: 10px;
}

.scrollable-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #d2dae2;
}
</style>
