<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-icon>
        mdi-vector-polygon
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-col class="pa-0">
        <v-hover v-slot="{ hover }" v-if="station.permanent">
          <v-list-item-subtitle
            class="row-pointer"
            @click="toMonitoringDetails"
            :title="$t('monitoring.toDetailsPage')"
            :class="
              hover
                ? 'primary--text font-weight-bold text-decoration-underline'
                : 'primary--text font-weight-bold '
            "
          >
            {{ station.name }}
          </v-list-item-subtitle>
        </v-hover>
      </v-col>
      <v-list-item-subtitle
        class="primary--text font-weight-bold"
        v-if="!station.permanent"
      >
        {{ station.name }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="text--primary">
        <v-progress-circular
          size="20"
          indeterminate
          v-if="actualValueLoading"
        ></v-progress-circular>
        <span v-html="displayActualValue" />
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-progress-circular
        indeterminate
        color="accent"
        v-if="dataLoading"
      ></v-progress-circular>

      <v-tooltip top v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            v-on="on"
            color="accent"
            @click="addTimeseries"
            :disabled="addButtonDisabled"
          >
            <v-icon v-if="addButtonDisabled">mdi-check-bold</v-icon>
            <v-icon v-else>mdi-chart-box-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("addVirtualStationToPlot") }}</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import virtualStationMixin from "@/core/mixins/virtualStation.mixin";
import { mapActions } from "vuex";

export default {
  name: "VirtualStationPolygon",
  props: ["station", "geometry", "activeTimestep", "close"],
  data: () => ({
    dataLoading: false,
    actualValueLoading: false
  }),
  mixins: [virtualStationMixin],
  computed: {
    displayActiveTimestep() {
      return this.activeRasterLayer.datetime.replace("T", " ");
    }
  },
  methods: {
    ...mapActions("baseline", ["updateSelectedStation"]),
    async addTimeseries() {
      this.dataLoading = await this.addTimeseriesToPlot();
    },
    toMonitoringDetails() {
      const client = this.$appConfig.keycloakClient;
      this.close();
      if (client === "hypos") {
        this.$router.push({
          name: "BaselineDashboard"
        });
      } else {
        this.$router.push({
          name: "MonitoringDetails",
          query: { timestep: this.activeTimestep },
          params: {
            analysisGeometry: this.geometry
          }
        });
      }
    },
    toBaseline() {
      this.$router.push({
        name: "BaselineSingle",
        params: {
          stationId: this.station.id
        }
      });
    }
  }
};
</script>

<style scoped>
.row-pointer {
  cursor: pointer;
}
</style>
